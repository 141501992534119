import React from "react";

import { Context, ServerCmsContextProcessorsMenuItem } from "@reactivated";

interface Props {}

const Menu = (props: { menu: ServerCmsContextProcessorsMenuItem[] }) => (
    <>
        {props.menu.map((menuitem) => (
            <li key={menuitem.pk} className="presentation">
                <a href={menuitem.url}>{menuitem.title}</a>
            </li>
        ))}
    </>
);

const SearchForm = (props: { inputID: string; className: string }) => (
    <form
        action="/search"
        method="get"
        className={props.className}
        role="search"
    >
        <label htmlFor={props.inputID} className="u-sr-only">
            Search the bakery
        </label>
        <input
            className="navigation__search-input"
            id={props.inputID}
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="q"
        />
        <div aria-hidden="true" className="navigation__search-icon">
            <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
                    fill="#333"
                />
            </svg>
        </div>
    </form>
);

export const GlobalHeader = (props: Props) => {
    const context = React.useContext(Context);
    return (
        <div className="header clearfix">
            <div className="container">
                <div className="navigation" data-navigation>
                    <a href="/" className="navigation__logo">
                        The Wagtail Bakery
                    </a>
                    <button
                        type="button"
                        className="navigation__mobile-toggle"
                        aria-label="Toggle mobile menu"
                        aria-expanded="false"
                        data-mobile-navigation-toggle
                    >
                        <span className="navigation__toggle-icon-bar"></span>
                        <span className="navigation__toggle-icon-bar"></span>
                        <span className="navigation__toggle-icon-bar"></span>
                    </button>

                    <nav
                        className="navigation__mobile"
                        data-mobile-navigation
                        hidden
                    >
                        <a href="/" className="navigation__logo">
                            The Wagtail Bakery
                        </a>
                        <ul className="navigation__items nav-pills">
                            <Menu menu={context.top_menu} />
                        </ul>
                        <SearchForm
                            inputID="mobile-search-input"
                            className="navigation__mobile-search"
                        />
                    </nav>

                    <nav className="navigation__desktop" aria-label="Main">
                        <ul className="navigation__items nav-pills">
                            <Menu menu={context.top_menu} />
                        </ul>
                    </nav>

                    <SearchForm
                        inputID="search-input"
                        className="navigation__search"
                    />
                </div>
            </div>
        </div>
    );
};
